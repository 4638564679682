import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import one from "../assets/img/xacsw2024/1.png";
import two from "../assets/img/xacsw2024/2.jpg";
import three from "../assets/img/xacsw2024/3.jpg";
import four from "../assets/img/xacsw2024/4.jpg";
import five from "../assets/img/xacsw2024/5.jpg";
import six from "../assets/img/xacsw2024/6.jpg";

const XACSW2024 = () => {
  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h2 className="newsPostTitle">
        第三届化学键与AI分子模拟XACS研讨会
        </h2>

        <div className="newsPostInfo">
        2024年07月12日
        </div>

        <p className="newsPostDesc">
        2024年7月1-5日，由郑州大学化学学院和福建省理论与计算化学重点实验室共同主办，XACS团队、郑州大学化学拔尖学生培养基地和厦门大学化学拔尖学生培养基地协办的<b>第三届化学键与AI分子模拟XACS研讨会(XACSW-2024)</b>在郑州大学成功举办。本次研讨会旨在满足广大理论与计算化学研究学者系统学习价键理论方法、能量分解分析方法和机器学习化学计算模拟的需求。
        </p>
        <img src={one} style={{ width: '60%', marginLeft: '300px', marginTop: '5px', marginDown: '0px' }} />
      
        <h3 className="newsPostSubTitle">
        关于XACS研讨会
        </h3>
        <p className='newsPostDesc'>
        自2012年至今，该系列研讨会已成功举办了四届国际研讨班和七届国内研讨班。本次研讨会以化学键理论和AI分子模拟为主题，邀请国际知名理论化学专家授课，系统介绍<b>价键理论、分子相互作用理论与分析方法以及AI加强计算化学</b>，详细讲解XACS云平台功能，并提供上机实践及案例分析。此次研讨会吸引了来自全国各地38所高校和科研机构的100余名师生参加。
        </p>
        <img src={two} style={{ width: '60%', marginLeft: '300px', marginTop: '5px', marginDown: '0px' }} />
        
        <h3 className="newsPostSubTitle">      
        研讨会开幕
        </h3>
        <p className='newsPostDesc'>
        7月2日上午研讨会正式开始，本次研讨会开幕式由郑州大学化学学院副院长蓝宇教授主持。郑州大学学术副校长、化学学院院长臧双全教授和福建省理论与计算化学重点实验室主任、厦门大学苏培峰教授开幕致辞。臧双全教授在致辞中表示本次研讨会为理论与计算化学领域的学者提供了一个宝贵的交流平台，期望大家能够通过此次研讨会深入交流，互相学习，共同推动该领域的进步与发展。苏培峰教授则强调了本次研讨会的重要性，并对参会者的积极参与表示感谢。
        </p>
        <img src={three} style={{ width: '60%', marginLeft: '300px', marginTop: '5px', marginDown: '0px' }} />

        <h3 className="newsPostSubTitle">      
        研讨会现场
        </h3>
        <p className='newsPostDesc'>
        在本次研讨会上，厦门大学吴玮教授、苏培峰教授、Pavlo Dral教授、周晨副教授、应富鸣高级工程师系统讲授了价键理论、分子相互作用理论和机器学习基础知识并详细介绍了原子计算套件(XACS)云平台功能、以及该套件所包括的XMVB, XEDA和MLatom软件的应用实例。北卡罗来纳大学格林斯伯勒分校莫亦荣教授、陕西师范大学王长崴副教授、中南大学林旭辉副教授讲授了块定域波函数的基本原理和理论基础，并通过具体算例展示了其在实际计算中的应用。
        </p>
        <img src={four} style={{ width: '60%', marginLeft: '300px', marginTop: '5px', marginDown: '0px' }} />
        <br/><br/><br/>
        <p className='newsPostDesc'>
        此外，研讨会还邀请了希伯来大学Sason Shaik教授、林奈大学Ran Friedman教授、厦门大学汪骋教授等国内外知名专家远程连线进行专题授课。
        </p>
        <img src={five} style={{ width: '60%', marginLeft: '300px', marginTop: '5px', marginDown: '0px' }} />
        <br/><br/><br/>
        {/* style={{marginLeft:'300px'}} */}
        <p className='newsPostDesc'>
        本次研讨会做了全面升级：
        <br/><b>系统化课程设置</b>：从基础到进阶，全面覆盖价键理论、分子相互作用分析和AI计算化学。
        <br/><b>上机实践操作</b>：提供上机实践机会，通过真实案例解析软件应用。
        <br/><b>国际化视野</b>：邀请多位国际知名学者分享最新研究成果和前沿理论。
        <br/><b>云计算功能</b>：详细介绍XACS云计算平台的功能和优势，为参会者提供便捷高效的计算工具。
        <br/><b>进阶课程</b>：为有深入研究需求的参会者提供进阶课程选择，进一步提升学术水平。
        <br/><b>师生系列学术交流</b>：全方位展示价键理论方法，能量分解分析方法和机器学习方法在实际化学体系中的应用。
        </p>

        <h3 className="newsPostSubTitle">      
        研讨会闭幕
        </h3>
        <p className='newsPostDesc'>
        7月5日下午研讨会圆满结束，厦门大学吴玮教授闭幕致辞，对所有参会者的参与表示衷心感谢，并期待在未来的XACS研讨会中继续与大家相聚，共同推动化学键与AI分子模拟领域的发展。
        </p>
        <img src={six} style={{ width: '60%', marginLeft: '300px', marginTop: '5px', marginDown: '0px' }} />
        <br/><br/>
        <p className='newsPostDesc'>
        此次研讨会的成功举办，不仅加强了学术界的交流与合作，也为推动计算化学与人工智能技术的融合应用提供了新的思路和契机。
        </p>

        <button
          onClick={() => { window.location.href = 'https://xacs.xmu.edu.cn/workshop2024'; }}
          className="all-news-button"
        >
        Learn More
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default XACSW2024;
