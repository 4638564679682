import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/logo-white.png';
import { BrowserRouter as Router, Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "../App.css";

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('currentLanguage') || 'cn');
  const changeLanguage = (lng) => {
    localStorage.setItem('currentLanguage', lng);
    setCurrentLanguage(lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    // Set initial language on app load
    i18n.changeLanguage(currentLanguage);
  }, [currentLanguage]);

  const [productsmenuOpen, setProductsMenuOpen] = useState(false);
  const toggleProductsMenuOpen = (isOpen) => {
    setProductsMenuOpen(isOpen);
  };
  const [docsmenuOpen, setDocsMenuOpen] = useState(false);
  const toggleDocsMenuOpen = (isOpen) => {
    setDocsMenuOpen(isOpen);
  };
  const [learnmenuOpen, setLearnMenuOpen] = useState(false);
  const toggleLearnMenuOpen = (isOpen) => {
    setLearnMenuOpen(isOpen);
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  const handleNavigation = (path) => {
    setActiveLink(path);
    navigate(path);
  };

  return (
    <div className="Nav">
      {currentLanguage === 'en' ? (
        <button className="language-switcher-button" onClick={() => changeLanguage('cn')}>English</button>
      ) : (
        <button className="language-switcher-button" onClick={() => changeLanguage('en')}>简体中文</button>
      )}

      <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} alt="Logo" style={{ width: 'auto', height: '90px' }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {/* PRODUCTS */}
              <Nav>
                <NavDropdown
                  className={activeLink === 'products' ? "active navbar-link" : "navbar-link"}
                  onMouseEnter={() => toggleProductsMenuOpen(true)}
                  onMouseLeave={() => toggleProductsMenuOpen(false)}
                  show={productsmenuOpen}
                  title={
                    <span onClick={() => handleNavigation('/products')}>
                      {i18n.t('NavBar.products')}
                    </span>
                  }
                  id="nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/cloudcomputing">
                    {i18n.t('NavBar.cloudcomputing')}
                  </NavDropdown.Item>
                  <NavDropdown.Header>
                    {i18n.t('NavBar.programs')}
                  </NavDropdown.Header>
                  <NavDropdown.Item as={Link} to="/xmvb">
                    XMVB
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/xeda">
                    XEDA
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/mlatom">
                    MLatom
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>

              {/* LEARN */}
              <Nav>
                <NavDropdown
                  className={activeLink === 'learn' ? "active navbar-link" : "navbar-link"}
                  onMouseEnter={() => toggleLearnMenuOpen(true)}
                  onMouseLeave={() => toggleLearnMenuOpen(false)}
                  show={learnmenuOpen}
                  title={
                    <span onClick={() => handleNavigation('/learn')}>
                      {i18n.t('NavBar.learn')}
                    </span>
                  }
                  id="nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/courses">
                  {i18n.t('NavBar.courses')}
                  </NavDropdown.Item>
                  <NavDropdown.Header>
                  {i18n.t('NavBar.workshop')}
                  </NavDropdown.Header>
                  <NavDropdown.Item as={Link} to="/xacsw2024">
                    XACSW-2024
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="https://xacs.xmu.edu.cn/docs/workshop-2023/">
                    XACSW-2023
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="https://xacs.xmu.edu.cn/docs/workshop_Torun/">
                    Torun-2023
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>

              {/* DOCS */}
              <Nav>
                <NavDropdown
                  className={activeLink === 'docs' ? "active navbar-link" : "navbar-link"}
                  onMouseEnter={() => toggleDocsMenuOpen(true)}
                  onMouseLeave={() => toggleDocsMenuOpen(false)}
                  show={docsmenuOpen}
                  title={
                    <span onClick={() => handleNavigation('/docs')}>
                      {i18n.t('NavBar.docs')}
                    </span>
                  }
                  id="nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="https://xacs.xmu.edu.cn/docs/cloud-computing/">
                    {i18n.t('NavBar.cloudcomputing')}
                  </NavDropdown.Item>

                  <NavDropdown.Header>
                    {i18n.t('NavBar.programs')}
                  </NavDropdown.Header>
                  <NavDropdown.Item as={Link} to="https://xacs.xmu.edu.cn/docs/xmvb/">
                    XMVB
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="https://xacs.xmu.edu.cn/docs/xeda/">
                    XEDA
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="https://xacs.xmu.edu.cn/docs/mlatom/">
                    MLatom
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>

              <Nav>
                <Nav.Link
                  as={Link} to="/news"
                  className={activeLink === 'news' ? "active navbar-link" : "navbar-link"}
                  onClick={() => onUpdateActiveLink('news')}>
                  {i18n.t('NavBar.news')}
                </Nav.Link>
                <Nav.Link
                  as={Link} to="/about"
                  className={activeLink === 'about' ? "active navbar-link" : "navbar-link"}
                  onClick={() => onUpdateActiveLink('about')}>
                  {i18n.t('NavBar.about')}
                </Nav.Link>
                <Nav.Link
                  as={Link} to="/contactus"
                  className={activeLink === 'contact' ? "active navbar-link" : "navbar-link"}
                  onClick={() => onUpdateActiveLink('contact')}>
                  {i18n.t('NavBar.contact')}
                </Nav.Link>
              </Nav>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <button className="cloud-button" onClick={() => window.location.href = 'https://xacs.xmu.edu.cn/newcloud/'}>
        {i18n.t('NavBar.login')}
      </button>
    </div>
  );
};

export default NavBar;
