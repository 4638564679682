import React from 'react';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import "../App.css";
import mlatom from "../assets/img/mlatom.png";
import { useTranslation } from 'react-i18next';
import IR from "../assets/img/strapi_img/IR/IR-cover.jpg";
import JCTC from "../assets/img/strapi_img/JCTC/JCTC_cover.png";

export default function SingleNewsCopy() {
  const { t, i18n } = useTranslation();
  const { loading, error, data } = useFetch('http://47.115.164.156:1337/api/posts?populate=*&sort[0]=Date:DESC');

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const currentLanguage = i18n.language; // Get current language

  return (
    <div className="post-list">
      {data.data
        .filter(post => (currentLanguage === 'cn' ? post.attributes.en : !post.attributes.en))
        .map(post => (
          <div className="post d-flex flex-column align-items-center" key={post.id} en={post.attributes.en}>
              <img className="postImg" src={"http://kesuansoft.com:1337" + post.attributes.Cover.data.attributes.url} alt={post.attributes.Title} />
              <div className="postcopyDate">
                {post.attributes.Date}
              </div>
              <div class="postTitleContainer">
                <span className="postcopyTitle">
                  <Link to={`/singlenews/${post.id}`} className="link">
                    {post.attributes.Title}
                  </Link>
                </span>
              </div>
              <hr />
          </div>
        ))}
    </div>
  );
}
