import React from 'react';
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import headerimg1 from "../assets/img/3d-representation-dna.jpg";
import headerimg2 from "../assets/img/abstract-chemistry-pattern.jpg";
import headerimg3 from "../assets/img/3d-representation-dna.jpg";
import "../App.css";

export const Banner = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/about");
  };

  const { i18n } = useTranslation();

  return (
    <section className="banner" id="home">
      <Container>
        <Row >
          <Col>
          <img src={headerimg1} alt="Header Img" />
            <Carousel>

              <Carousel.Item>
                <div className="carousel-item-content">
                  <Row >
                    <Col>
                      <div>
                        <span className="tagline">{i18n.t('Banner.tagline')}</span>
                        <h1 className="header">
                          {i18n.t('Banner.header')}
                        </h1>
                        <span className="strapline">{i18n.t('Banner.strapline')}</span>
                        <button className="learnmore" onClick={handleNavigate}>
                          {i18n.t('Banner.learnmore')}
                          <ArrowRightCircle size={25} />
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="carousel-item-content">
                  <Row className="align-items-center">
                    <Col>
                      <div>
                        <span className="tagline">{i18n.t('Banner.tagline2')}</span>
                        <h1 className="header">
                          {i18n.t('Banner.header2')}
                        </h1>
                        <span className="strapline">{i18n.t('Banner.strapline2')}</span>
                        <button className="learnmore" onClick={handleNavigate}>
                          {i18n.t('Banner.learnmore')}
                          <ArrowRightCircle size={25} />
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Carousel.Item>

              <Carousel.Item>
                <div className="carousel-item-content">
                  <Row className="align-items-center">
                    <Col>
                      <div>
                        <span className="tagline">{i18n.t('Banner.tagline3')}</span>
                        <h1 className="header">
                          {i18n.t('Banner.header3')}
                        </h1>
                        <span className="strapline">{i18n.t('Banner.strapline3')}</span>
                        <button className="learnmore" onClick={handleNavigate}>
                          {i18n.t('Banner.learnmore')}
                          <ArrowRightCircle size={25} />
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Carousel.Item>

            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
