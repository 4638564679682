import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import wechatqr from "../assets/img/wechat-qr.png";
import slackqr from "../assets/img/slack-qr.png";
import facebookqr from "../assets/img/facebook-qr.png";
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h1 className="newsPostTitle">
          {i18n.t("Contact.contactus")}
        </h1>
        <p className="newsPostDesc">
        {i18n.t("Contact.contact1")}
        <br/><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {i18n.t("Contact.contact2")}
        </p>
      </div>
        <div className='image-container'>
            <img src={wechatqr} alt="Icon" />
            <img src={slackqr} alt="Icon" />
            <img src={facebookqr} alt="Icon" />
        </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
