import "../App.css";
// import mlatom from "../assets/img/mlatom.png";
import { Footer } from "../components/Footer";
import Navbar from "../components/NavBar";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";

export default function SingleEventPage() {
  // const { id } = useParams(); // Get post id from URL
  const { loading, error, data } = useFetch(`http://localhost:1337/api/events/1`);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const post = data.data;

  return (
    <div className="eventPost">
      <Navbar/>
      <div className="eventPostWrapper">
        <h1 className="eventPostTitle">
          {post.attributes.Title}
        </h1>
        <div className="eventPostInfo">
          {post.attributes.Date}
        </div>
        <p className="eventPostDesc">
          {post.attributes.Content}
        </p>
      </div>
      <Footer/>
    </div>
  );
}