import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import mlatom from "../assets/img/mlatom.png";
import workshop from "../assets/img/workshop.png";

const Workshops = () => {
  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h2 className="newsPostTitle">
          Workshops
        </h2>
        <p className="newsPostDesc">
        Our workshops cover various topic in AI & Chemistry. Participants receive hands-on training with the XACS suite, developed by Xiamen University, and learn about its software components XMVB, XEDA, and MLATOM. Special reports are delivered by esteemed professors highlighting their research advancements using XACS. 
        </p>
        <button
          onClick={() => { window.location.href = 'https://xacs.xmu.edu.cn/newcloud/'; }}
          className="all-news-button"
        >
          Get Started
        </button>
        <img src={workshop} style={{ height: '80%', width: '60%', marginLeft: '300px', marginTop: '30px' }} />
      </div>
      <Footer />
    </div>
  );
};

export default Workshops;
