import { Link } from "react-router-dom";
import "../App.css";
import mlatom from "../assets/img/mlatom.png"
import React from 'react'
import useFetch from '../hooks/useFetch'


export default function SingleNews() {
  const { loading, error, data } = useFetch('http://localhost:1337/api/events')

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error</p>

  console.log(data)

  return (
  <div className="post-list">
      {data.data.map(post => (
        <div className="post" key={post.id}>
          <div id={post.id}>
            <img className="postImg" src={mlatom} alt={post.attributes.Title} />
            <div className="postInfo">
              <span className="postTitle">
                <Link to={`/singleevent`} className="link">
                  {post.attributes.Title}
                </Link>
              </span>
              <hr />
              {/* <div className="postContent">
                {post.attributes.Content}a
              </div> */}
            </div>
          </div>
        </div>
      ))}
    </div>
     
  );
}