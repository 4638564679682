import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import mlatom from "../assets/img/mlatom.png";
import course from "../assets/img/course.png";
import { useTranslation } from 'react-i18next';
import CourseTitle from '../assets/img/course/course-title.png'
import CourseStep1 from '../assets/img/course/step-1.png'
import CourseStep2 from '../assets/img/course/step-2.png'
import CourseStep3 from '../assets/img/course/step-3.png'
import "./Courses.css"
const Courses = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="newsPost coursePage" style={{ paddingTop: '150px ' }}>
      <NavBar />
      <div className="container workshop-container">
        <img className="banner" src={CourseTitle} />
        <p className="mt-5">
          This mini-course gives a practical introduction into computational chemistry and artificial intelligence (AI).
          Beginners will find here an easy way to start in complicated topics. The seasoned computational chemists will
          have an opportunity to rapidly get upgrade their skills with the AI tools. The course is unique as it does not
          require any programing skills or knowledge of Python to plunge into AI in computational chemistry.<br />
          This course is using the state-of-the-art <a className="inline-hyper" href="http://mlatom.com/">MLatom</a> program package designed for
          AI-enhanced computational
          chemistry and contains the computational exercises which can be carried out on the <a className="inline-hyper"
            href="https://xacs.xmu.edu.cn/">XACS cloud computing
            platform</a> .<br />
          The course’s author Prof. <a className="inline-hyper" href="http://dr-dral.com/">Pavlo O. Dral</a> is one of the leading experts in the
          field, the founder and lead
          developer of MLatom, co-founder of XACS, an Editor and contributor to the popular book “<a className="inline-hyper"
            href="https://www.sciencedirect.com/book/9780323900492/quantum-chemistry-in-the-age-of-machine-learning">Quantum
            Chemistry in the
            Age of Machine Learning</a>”, and the author of over 50 publications on AI in computational chemistry.
        </p>
        <div className="title-block">
          <h3>What you can learn from it?</h3>
          <div className="seg"></div>
        </div>
        <div className="mt-2">
          <ul>
            <li>Lecture 1. Quantum chemical levels and machine learning</li>
            <li>Lecture 2. Universal machine learning models</li>
            <li>Lecture 3. Why does DFT work? Improving upon it</li>
            <li>Lecture 4. ML potentials and algorithms</li>
            <li>Lecture 5. Molecular dynamics</li>
            <li>Lecture 6. Is your data sufficient for ML? Getting data</li>
            <li>Lecture 7. Spectroscopy</li>
            <li>Lecture 8. Reaction modeling</li>
          </ul>
        </div>
        <div className="title-block">
          <h3>How to access it?</h3>
          <div className="seg"></div>
        </div>
        <div className="mt-2">
          <p><span style={{ fontWeight: "bolder" }}>Click here:</span><a className="inline-hyper"
            href="https://xacs.xmu.edu.cn/newcloud/learning/docs">online course</a></p>
          <p>
            <span style={{ fontWeight: "bolder" }}>Step 1</span> Register the XACS Cloud account (free)
          </p>
          <div style={{ "width": "100%", textAlign: "center", }}>
            <img className="step" src={CourseStep1} />
          </div>
          <p>
            <span style={{ fontWeight: "bolder" }}>Step 2</span> Log in and go to ‘Courses’
          </p>
          <div style={{ "width": "100%", textAlign: "center", }}>
            <img className="step" src={CourseStep2} />
          </div>
          <p>
            <span style={{ fontWeight: "bolder" }}>Step 3</span> Choose the course you need and unlock it<br />
            You can choose the Chinese or English version, they are the same!
          </p>
          <div style={{ "width": "100%", textAlign: "center", }}>
            <img className="step" src={CourseStep3} />
          </div>
          <p>
            <span style={{ fontWeight: "bolder" }}>Step 4</span> Click on unlock and follow the instructions<br />
            Please send any feedback to <a href="dral@xmu.edu.cn">dral@xmu.edu.cn</a> or on <a
              href="https://xacs-support.slack.com/join/shared_invite/zt-1gm1lpn68-pReQhfYGu813eCqwmvdGvA#/shared-invite/email">Slack</a>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Courses;
