import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import  compute from "../assets/img/compute.png";
import { useTranslation, Trans } from 'react-i18next';

const Computing = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h2 className="newsPostTitle">
        {i18n.t('Computing.cloudcomputing')}
        </h2>
        <p className="newsPostDesc">
        {i18n.t('Computing.cloudcomputingintro')}
        </p>
        <button
          onClick={() => { window.location.href = 'https://xacs.xmu.edu.cn/docs/cloud-computing/'; }}
          className="all-news-button"
        >
        {i18n.t('Computing.usersmanual')}
        </button>
        <img src={compute} style={{ height: '80%', width: '60%', marginLeft: '300px', marginTop: '30px' }} />
      </div>
      <Footer />
    </div>
  );
};

export default Computing;
