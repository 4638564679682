import SingleEvent from "./SingleEvent";
import "../App.css";

export default function HomeEvents() {
  return (
    <div className="posts">
      <h2>Events</h2>
      <div className="post-list">
        <SingleEvent />
        <SingleEvent />
      </div>
    </div>
  );
}