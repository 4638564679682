import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import mlatom from "../assets/img/mlatom.png";
import { useTranslation } from 'react-i18next';

const MLatom = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h2 className="newsPostTitle">
          MLatom
        </h2>
        <p className="newsPostDesc">
          {i18n.t("MLatom.intro")}
          {/* It is an open-source program package that leverages the power of AI to enhance computational chemistry. The package supports many AI and quantum chemical methods and the users can also train their AI models to perform a wide variety of simulations. */}
        </p>
        <button
          onClick={() => { window.location.href = 'https://xacs.xmu.edu.cn/docs/mlatom/'; }}
          className="all-news-button"
        >
          {i18n.t("MLatom.manuel")}
        </button>
        <div className=' d-flex flex-column align-items-center'>
          <img src={mlatom} style={{ height: 'auto', width: '50%', marginTop: '30px' }} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MLatom;
