import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ProjectCardCompute } from "./ProjectCardCompute";
import { ProjectCardLearn } from "./ProjectCardLearn";
import computimg from "../assets/img/compute.png";
import workshop from "../assets/img/workshop.png";
import course from "../assets/img/course.png";
import xmvb from "../assets/img/logo1.png";
import mlatom from "../assets/img/logo2.png";
import xeda from "../assets/img/logo3.png";
import 'animate.css';
import { useTranslation, Trans } from 'react-i18next';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {
  const { i18n, t } = useTranslation();

  const programs = [
    {
      title: "XMVB",
      linkUrl: "/xmvb",
      description: i18n.t('Projects.xmvbintro'),
      imgUrl: xmvb,
    },
    {
      title: "XEDA",
      linkUrl: "/xeda",
      description: i18n.t('Projects.xedaintro'),
      imgUrl: xeda,
    },
    {
      title: "MLatom",
      linkUrl: "/mlatom",
      description: i18n.t('Projects.mlatomintro'),
      imgUrl: mlatom,
    },
  ];

  const compute = [
    {
      title: i18n.t('Projects.cloudcomputing'),
      linkUrl: "/cloudcomputing",
      description: i18n.t('Projects.cloudcomputingintro'),
      imgUrl: computimg,
    },
  ];

  const learn = [
    {
      title: i18n.t('Projects.courses'),
      linkUrl: "/courses",
      description: i18n.t('Projects.coursesintro'),
      imgUrl: course,
    },
    {
      title: i18n.t('Projects.workshops'),
      linkUrl: "/workshops",
      description: i18n.t('Projects.workshopsintro'),
      imgUrl: workshop,
    },
  ];
  


  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>{i18n.t('Projects.whatwedo')}</h2>

                {/* THREE TABS */}
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item >
                      <Nav.Link eventKey="first">
                       {i18n.t('Projects.programs')}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className>
                      <Nav.Link eventKey="second">
                        {i18n.t('Projects.computing')}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className>
                      <Nav.Link eventKey="third">
                      {i18n.t('Projects.teaching')}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  {/* ITEMS */}
                  {/* Need down line for keeping projectcards seperate for each pill, rather than following before ones to another line */}
                  <Tab.Content >
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          programs.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {/* style={{ display: 'flex', justifyContent: 'center' }} */}
                    <Row>
                        {
                          compute.map((project, index) => {
                            return (
                              <ProjectCardCompute
                                key={index}
                                {...project}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      {/* className="flex-container" */}
                    <Row>
                        {
                          learn.map((project, index) => {
                            return (
                              <ProjectCardLearn
                                key={index}
                                {...project}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>

                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
