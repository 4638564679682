import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import "../App.css";
import SingleNews from '../components/SingleNews';
import SingleNewsCopy from '../components/SingleNewsCopy';
import { useTranslation, Trans } from 'react-i18next';

const News = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className='news'>
      <NavBar />
      <h2>
        {i18n.t('News.news')}
      </h2>
      <div className="news-list">
        <SingleNewsCopy />
      </div>
      <Footer />
    </div>
  );
};

export default News;
