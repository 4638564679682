import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import computimg from "../assets/img/compute.png";
import xmvb from "../assets/img/logo1.png";
import mlatom from "../assets/img/logo2.png";
import xeda from "../assets/img/logo3.png";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";
import { Link } from 'react-router-dom';
import '../App.css'
import { useTranslation } from 'react-i18next';
import docsicon from "../assets/img/coursesicon.svg";
import cloudicon from "../assets/img/cloudicon.png";

const Docs = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language; // Get current language

  return (
    <div className='docs'>
      <NavBar/>
      <h2>{i18n.t('Docs.docs')}</h2>
      <h3>{i18n.t('Docs.cloudcomputing')}</h3>
        <Row xs={1} md={3} className="g-3">
            {Array.from({ length: 1 }).map((_, idx) => (
              <Col key={idx}>
                <a href="https://xacs.xmu.edu.cn/docs/cloud-computing/" target="_blank" rel="noopener noreferrer">
                <Card className="hover-card"
                  style={{ 
                    marginTop: '0px', 
                    marginBottom: '50px',
                    marginLeft: '45px', 
                    marginRight: '30px', 
                    boxShadow: '0 4px 8px rgba(86, 77, 107, 0.2), 0 6px 20px rgba(86, 77, 107, 0.19)',
                    width: '350px',
                    height: '350px'
                  }}>
                  <Card.Title
                    style = {{
                      color: '#564d6b',
                      marginTop: '25px',
                      textAlign: 'center', 
                      fontSize: '30px',
                    }}
                  >{i18n.t('Docs.cloudcomputing')}</Card.Title>
                  <Card.Img className='productsimgcompute' src={cloudicon} />
                  <Card.Body>
                    <Card.Text
                      style = {{
                        color: '#564d6b',
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginTop:'40px',
                        marginBottom: '10px',
                        textAlign: 'center', 
                      }}
                    >
                    {i18n.t('Docs.cloudcomputingintro')}
                    {/* Read the documentation for cloud commputing. */}
                    </Card.Text>
                  </Card.Body>
                </Card>
                </a>
              </Col>
            ))}
        </Row>

      <h3>{i18n.t('Docs.programs')}</h3>
      <Row xs={1} md={3} className="g-3">
            {Array.from({ length: 1 }).map((_, idx) => (
              <Col key={idx}>
                <a href="https://xacs.xmu.edu.cn/docs/xmvb/" target="_blank" rel="noopener noreferrer">
                <Card className="hover-card"
                  style={{ 
                    marginTop: '0px', 
                    marginLeft: '45px', 
                    marginRight: '30px', 
                    boxShadow: '0 4px 8px rgba(86, 77, 107, 0.2), 0 6px 20px rgba(86, 77, 107, 0.19)',
                    width: '350px',
                    height: '350px'
                  }}>
                  <Card.Title
                  style = {{
                    color: '#564d6b',
                    marginTop: '25px',
                    textAlign: 'center', 
                    fontSize: '30px',
                  }}
                  >XMVB</Card.Title>
                  <Card.Img className='productsimgcompute' src={docsicon} />
                  <Card.Body>
                  <Card.Text
                   style = {{
                    color: '#564d6b',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '10px',
                    textAlign: 'center', 
                  }}>
                    {i18n.t('Docs.xmvbintro')}
                  {/* Documentation to the XMVB package that provides an ab initio computing platform for various VB approaches. */}
                  </Card.Text>
                  </Card.Body>
                </Card>
                </a>
              </Col>
            ))} 

            {Array.from({ length: 1 }).map((_, idx) => (
              <Col key={idx}>
                <a href="https://xacs.xmu.edu.cn/docs/xeda/" target="_blank" rel="noopener noreferrer">
                <Card className="hover-card"
                  style={{ 
                    marginTop: '0px', 
                    marginLeft: '45px', 
                    marginRight: '30px', 
                    boxShadow: '0 4px 8px rgba(86, 77, 107, 0.2), 0 6px 20px rgba(86, 77, 107, 0.19)',
                    width: '350px',
                    height: '350px',
                    textAlign: 'center',
                  }}>
                  <Card.Title
                  style = {{
                    color: '#564d6b',
                    marginTop: '25px',
                    textAlign: 'center', 
                    fontSize: '30px',
                  }}
                  >XEDA</Card.Title>
                  <Card.Img className='productsimgcompute' src={docsicon} />
                  <Card.Body>
                  <Card.Text
                   style = {{
                    color: '#564d6b',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '10px',
                    textAlign: 'center', 
                  }}>
                    {i18n.t('Docs.xedaintro')}
                  {/* Documentation to the XEDA package that provides an analysis platform for various quantom chemistry calculations. */}
                  </Card.Text>
                  </Card.Body>
                </Card>
                </a>
              </Col>
            ))}  

            {Array.from({ length: 1 }).map((_, idx) => (
              <Col key={idx}>
                <a href="https://xacs.xmu.edu.cn/docs/mlatom/" target="_blank" rel="noopener noreferrer">
                <Card className="hover-card"
                  style={{ 
                    marginTop: '0px', 
                    marginLeft: '45px', 
                    marginRight: '30px', 
                    boxShadow: '0 4px 8px rgba(86, 77, 107, 0.2), 0 6px 20px rgba(86, 77, 107, 0.19)',
                    width: '350px',
                    height: '350px'
                  }}>
                  <Card.Title
                  style = {{
                    color: '#564d6b',
                    marginTop: '25px',
                    textAlign: 'center', 
                    fontSize: '30px',
                  }}
                  >MLatom</Card.Title>
                  <Card.Img className='productsimgcompute' src={docsicon} />
                  <Card.Body>
                  <Card.Text
                   style = {{
                    color: '#564d6b',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '10px',
                    textAlign: 'center', 
                    position: 'relative',
                  }}>
                    {i18n.t('Docs.mlatomintro')}
                  {/* Documentation to the MLatom package that leverages the power of AI to enhance computational chemistry. */}
                  </Card.Text>
                  </Card.Body>
                </Card>
                </a>
              </Col>
            ))}
    </Row>
    </div>
  );
}

export default Docs;