import React from 'react';
import { Col } from 'react-bootstrap';

export const ProjectCard = ({ title, description, imgUrl, linkUrl }) => {
  return (
    // size={12} sm={6} md={4}
    <Col >
          
    <div className="proj-imgbx">
      <img className='proj-img' src={imgUrl} />
      <div className="proj-txtx">
        <h4><a href={linkUrl} target="_blank" rel="noopener noreferrer">{title}</a></h4>
        <span>{description}</span>
      </div>
    </div>
  </Col>
  );
};


   