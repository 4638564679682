import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import video from "../assets/about_video.mp4";
import '../App.css';
import xmvb from "../assets/img/xmvb.png";
import { useTranslation } from 'react-i18next';

const XMVB = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h2 className="newsPostTitle">
          XMVB
        </h2>
        <p className="newsPostDesc">
        {i18n.t("XMVB.intro")}
        
        </p>
        <button
          onClick={() => { window.location.href = 'https://xacs.xmu.edu.cn/docs/xmvb/'; }}
          className="all-news-button"
        >
         {i18n.t("XMVB.manuel")}
        </button>
        <div className=' d-flex flex-column align-items-center'>
          <img src={xmvb} style={{ height: 'auto', width: '50%', marginTop: '30px' }} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default XMVB;



