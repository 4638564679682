import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import xeda from "../assets/img/xeda.png";
import { useTranslation } from 'react-i18next';

const XEDA = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h2 className="newsPostTitle">
          XEDA
        </h2>
        <p className="newsPostDesc">
          {i18n.t("XEDA.intro")}
          {/* Xiamen Energy Decomposition Analysis (XEDA) is a general use and multipurpose energy decomposition analysis (EDA) program. It implements the LMO-EDA and GKS-EDA methods and their extensions. XEDA provides an analysis platform for quantum chemistry calculation to understand molecular interactions and strong chemical bonds in various environments. XEDA is highly efficient with a similar computational scaling of single point energy calculations, and thus can be applied to very large molecular systems. */}
        </p>
        <button
          onClick={() => { window.location.href = 'https://xacs.xmu.edu.cn/docs/xeda/'; }}
          className="all-news-button"
        >
          {i18n.t("XEDA.manuel")}
        </button>
        <div className=' d-flex flex-column align-items-center'>
          <img src={xeda} style={{ height: 'auto', width: '50%', marginTop: '30px' }} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default XEDA;
