import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import "../App.css";
import Docs from '../components/Docs';

const DocsPage = () => {
  return (
    <div>
      <NavBar />
      <Docs />
      <Footer />
    </div>
  );
};

export default DocsPage;
