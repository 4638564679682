import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import video from "../assets/about_video.mp4";
import { useTranslation } from 'react-i18next';

const About = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h1 className="newsPostTitle">
          {i18n.t("About.about")}
        </h1>
        <p className="newsPostDesc">
        {i18n.t("About.intro1")}
        <br/><br/>
        <div
          dangerouslySetInnerHTML={{ __html: t('About.intro2') }}
        />
        </p>
        <video className='aboutVideo' width="550" controls >
          <source src={video} type="video/mp4"/>
        </video>
      </div>
      <Footer />
    </div>
  );
};

export default About;
