import { Container, Row, Col } from "react-bootstrap";
import logo from "../assets/img/logo2.png";
import wechat from "../assets/img/wechat.svg";
import slack from "../assets/img/slack.svg";
import facebook from "../assets/img/facebook.svg";
import bilibili from "../assets/img/bilibili.svg";
import youtube from "../assets/img/youtube.svg";
import twiter from "../assets/img/twiter.png";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { i18n, t } = useTranslation();

  const wechatClickHandler = () => {
    window.open('/wechatQR', 'wechatQR', 'resizable,height=600,width=600')
  }

  return (
    <footer className="footer">
      <Container>
        <Row >
          {/* size={12} sm={2} */}
          <Col >
            <img src={logo} alt="Logo" style={{ marginRight: '0px', width: '150px', height: '110px' }} />
          </Col>

          {/* Products */}
          <Col size={12} sm={6}>
            <Row>
              <Col xs={12} sm={3}>
                <div className="footer-section">
                  <div className="footer-title">{i18n.t("Footer.products")}</div>
                  <div className="footer-link"><a href="/cloudcomputing">{i18n.t("Footer.cloudcomputing")}</a></div>
                  <div className="footer-link"><a href="/xmvb">
                    {i18n.t("Footer.xmvb")}
                  </a></div>
                  <div className="footer-link"><a href="/xeda">
                    {i18n.t("Footer.xeda")}
                  </a></div>
                  <div className="footer-link"><a href="/mlatom">
                    {i18n.t("Footer.mlatom")}
                  </a></div>
                </div>
              </Col>

              {/* Learn */}
              <Col xs={12} sm={3}>
                <div className="footer-section">
                  <div className="footer-title">{i18n.t("Footer.learn")}</div>
                  <div className="footer-link"><a href="/courses">{i18n.t("Footer.courses")}</a></div>
                  <div className="footer-link"><a href="/workshops">{i18n.t("Footer.workshops")}</a></div>
                </div>
              </Col>

              {/* Docs */}
              <Col xs={12} sm={3}>
                <div className="footer-section">
                  <div className="footer-title">{i18n.t("Footer.docs")}</div>
                  <div className="footer-link"><a href="https://xacs.xmu.edu.cn/docs/cloud-computing/">
                    {i18n.t("Footer.cloudcomputing")}
                  </a></div>
                  <div className="footer-link"><a href="https://xacs.xmu.edu.cn/docs/xmvb/">
                    {i18n.t("Footer.xmvb")}
                  </a></div>
                  <div className="footer-link"><a href="https://xacs.xmu.edu.cn/docs/xeda/">
                    {i18n.t("Footer.xeda")}
                  </a></div>
                  {/* TO DO:
                  WILL NEED TO MAKE THE LINK TO CN VERSION WHEN LAN=CN */}
                  <div className="footer-link"><a href="https://xacs.xmu.edu.cn/docs/mlatom/">
                    {i18n.t("Footer.mlatom")}
                  </a></div>
                </div>
              </Col>

              {/* Information */}
              <Col xs={12} sm={3}>
                <div className="footer-section">
                  <div className="footer-title">{i18n.t("Footer.information")}</div>
                  <div className="footer-link"><a href="/news">{i18n.t("Footer.news")}</a></div>
                  <div className="footer-link"><a href="/about">{i18n.t("Footer.about")}</a></div>
                  <div className="footer-link"><a href="/contactus">{i18n.t("Footer.contactus")}</a></div>
                  <div className="footer-link"><a href="/privacypolicy">{i18n.t("Footer.privacypolicy")}</a></div>
                </div>
              </Col>

            </Row>
          </Col>

          <Col size={12} sm={4} className="ml-auto text-right text-sm-end" >
            <div className="social-icon">
              <a onClick={wechatClickHandler} target="_blank" rel="noopener noreferrer">
                <img src={wechat} alt="Icon" />
              </a>
              <a href="https://xacs-support.slack.com/ssb/redirect" target="_blank" rel="noopener noreferrer">
                <img src={slack} alt="Icon" />
              </a>
              /<a href="https://www.facebook.com/groups/xacscloud" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="Icon" />
              </a>
              <a href="https://space.bilibili.com/360261432" target="_blank" rel="noopener noreferrer">
                <img src={bilibili} alt="Icon" />
              </a>
              <a href="https://www.youtube.com/@XACScloud" target="_blank" rel="noopener noreferrer">
                <img src={youtube} alt="Icon" />
              </a>
              <a href="https://x.com/XACSprogram" target="_blank" rel="noopener noreferrer">
                <img src={twiter} alt="Icon" />
              </a>
            </div>
            <p>{i18n.t("Footer.email")}</p>
            <p>{i18n.t("Footer.copyright")}</p>
          </Col>
        </Row>

      </Container>
    </footer>
  )
}