import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import HomeEvents from '../components/HomeEvents';
import "../App.css";

const Events = () => {
  return (
    <div>
      <NavBar />
      <HomeEvents />
      <Footer />
    </div>
  );
};

export default Events;
