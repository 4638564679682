import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import xeda from "../assets/img/xeda.png";

const PrivacyPolicy = () => {
  return (
    <div className="newsPost">
      <NavBar />
      <div className="newsPostWrapper">
        <h2 className="newsPostTitle">
        XACS cloud computing privacy policy
        </h2>
        <p className="newsPostDesc">
        This Privacy Policy applies to access to and use of our services through the website of XACS cloud computing.
        We will follow the principles of legality, legitimacy and necessity to collect and use your personal information. Specifically, we collect and process personal information of the users to provide them with corresponding services. Every user is obliged to truthfully provide their own personal information and not information of other people. We reserve the right to check the legal source of personal information at any time.
        </p>
        <br/>
        <p className="newsPostDesc">
        <h3>User account information</h3>
        <br/>
        1. User account information, including what you provided during the registration in the XACS cloud computing platform:
        <br/>
        (1) The information the user filled in or provided, which includes the user’s name, e-mail address, postal code, organization, title, etc.;
        <br/>
        (2) computing records on XACS cloud computing platform, including the user’s historical task information and software downloads.
        <br/>
        2. We collect user’s personal information to provide the user with services and improve service quality, thus, XACS cloud computing platform only uses the user’s information for the following purposes:
        <br/>
        (1) to provide the user with various services and to improve these services;
        <br/>
        (2) in compliance with the requirements of relevant laws and regulations, send information to the user they might be interested in, including but not limited to service information.
        </p>
        <br/><br/>
        <p className="newsPostDesc">
        <h3>User computing data</h3>
        <br/>
        1. We understand and acknowledge that all the data the user uploads, downloads, distributes and processes through the services provided by XACS cloud computing or other means are the user’s data fully owned by the user.
        <br/>
        2. The user should be responsible for the source and content of their computing data. XACS cloud computing platform prompts the user to carefully judge the legitimacy of the data source and content. All results and responsibilities caused by user's business data content violating laws, regulations, or national policies shall be borne by the user themself.
        <br/>
        3. With regard to users’ computing data, XACS cloud computing platform will not make any unauthorized use or disclosure except to implement the user’s service requirements. Exceptions for the following circumstances:
        <br/>
        (1) we have the obligation to provide cooperation in accordance with the requirements of relevant laws, regulations or policy documents and disclose to third parties or administrative, judicial and other institutions when relevant state authorities query or access users’ data according to law;
        <br/>
        (2) if the user and the XACS cloud computing platform reached a special agreement separately.
        <br/>
        4. The user can delete and change their computing data by themself. If the user releases the service or deletes data by themself, XACS cloud computing platform will delete the user’s data and will not retain such data according to the user’s instructions. The user should be careful while deleting and changing data.
        <br/>
        5. It’s optional for the user the share their data with XACS computing platform developers, which is, however, strongly encouraged for such purposes as improving service and creating better machine learning models.
        <br/>
        6. If the user wants to update or delete the user’s account information, they can request updating or deleting this information by contacting us.
        </p>

      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
