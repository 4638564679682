import { useEffect, useState } from "react"

const useFetch = (url) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  console.log("Data:", data); // Log data to see its structure and contents
  console.log("Error:", error); // Log error if any
  console.log("Loading:", loading); // Log loading state

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      
      try {
        const res = await fetch(url)
        const json = await res.json()

        .then(data => {
          setData(data);
          setLoading(false);
        })
      } catch (error) {
        setError(error)
        setLoading(false)
      } 
    }

    fetchData();
  }, [url]
  )

  return { loading, error, data }
}

export default useFetch