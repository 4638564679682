import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Link } from "react-router-dom";
import "./index.css";
import "./i18n";
import Home from './routes/Home';
import DocsPage from './routes/DocsPage';
import About from './routes/About';
import ContactUs from './routes/ContactUs';
import News from './routes/News';
import Events from './routes/Events';
import SingleNewsPage from './routes/SingleNewsPage';
import SingleEventPage from './routes/SingleEventPage';
import ProductsPage from './routes/ProductsPage';
import Learn from './routes/Learn';
import XMVB from './routes/XMVB';
import XEDA from './routes/XEDA';
import MLatom from './routes/MLatom';
import Computing from './routes/Computing';
import Courses from './routes/Courses';
import Workshops from './routes/Workshops';
import PrivacyPolicy from './routes/PrivacyPolicy';
import XACSW2024 from "./routes/XACSW2024";
import WechatQR from './routes/WechatQR'
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/news",
    element: <News />,
  },
  {
    path: "/docs",
    element: <DocsPage />,
  },
  {
    path: "/events",
    element: <Events />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path:"/singlenews/:id",
    element: <SingleNewsPage />,
  },
  {
    path:"/singleevent",
    element: <SingleEventPage />,
  },
  {
    path: "/products",
    element: <ProductsPage />,
  },
  {
    path: "/learn",
    element: <Learn />,
  },
  {
    path: "/xmvb",
    element: <XMVB />,
  },
  {
    path: "/xeda",
    element: <XEDA />,
  },
  {
    path: "/mlatom",
    element: <MLatom />,
  },
  {
    path: "/cloudcomputing",
    element: <Computing />,
  },
  {
    path: "/courses",
    element: <Courses />,
  },
  {
    path: "/workshops",
    element: <Workshops />,
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/xacsw2024",
    element: <XACSW2024 />,
  },
  {
    path: "/wechatQR",
    element: <WechatQR />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);