import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";
import { Link } from 'react-router-dom';
import '../App.css'
import computimg from "../assets/img/compute.png";
import xmvb from "../assets/img/logo1.png";
import mlatom from "../assets/img/logo2.png";
import xeda from "../assets/img/logo3.png";
import { ProjectCard } from "./ProjectCard";
import { useTranslation, Trans } from 'react-i18next';
import cloudicon from "../assets/img/cloudicon.png";


const Products = () => {
  const { i18n, t } = useTranslation();

  return (
    <div className='docs'>
      <h2>
        {i18n.t('Products.products')}
      </h2>

      <h3>
      {i18n.t('Products.cloudcomputing')}
      </h3>
        <Row xs={1} md={3} className="g-3">
            {Array.from({ length: 1 }).map((_, idx) => (
              <Col key={idx}>
                <a href="https://xacs.xmu.edu.cn/software/xmvb" target="_blank" rel="noopener noreferrer">
                <Card className="hover-card"
                  style={{ 
                    marginTop: '0px', 
                    marginBottom: '50px',
                    marginLeft: '45px', 
                    marginRight: '30px', 
                    boxShadow: '0 4px 8px rgba(86, 77, 107, 0.2), 0 6px 20px rgba(86, 77, 107, 0.19)',
                    width: '350px',
                    height: '350px'
                  }}>
                  <Card.Title
                    style = {{
                      color: '#564d6b',
                      marginTop: '25px',
                      textAlign: 'center', 
                      fontSize: '30px',
                    }}
                  >
                    {i18n.t('Products.cloudcomputing')}
                  </Card.Title>
                  <Card.Img className='productsimgcompute' src={cloudicon} />
                  <Card.Body>
                    <Card.Text
                      style = {{
                        color: '#564d6b',
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginTop:'40px',
                        marginBottom: '10px',
                        textAlign: 'center', 
                      }}
                    >
                    {i18n.t('Products.cloudcomputingintro')}
                    </Card.Text>
                  </Card.Body>
                </Card>
                </a>
              </Col>
            ))}
        </Row>


      <h3>
        {i18n.t('Products.programs')}
      </h3>
        <Row xs={1} md={3} className="g-3">
            {Array.from({ length: 1 }).map((_, idx) => (
              <Col key={idx}>
                <a href="https://xacs.xmu.edu.cn/software/xmvb" target="_blank" rel="noopener noreferrer">
                <Card className="hover-card"
                  style={{ 
                    marginTop: '0px', 
                    marginBottom: '50px',
                    marginLeft: '45px', 
                    marginRight: '30px', 
                    boxShadow: '0 4px 8px rgba(86, 77, 107, 0.2), 0 6px 20px rgba(86, 77, 107, 0.19)',
                    width: '350px',
                    height: '350px'
                  }}>
                  <Card.Title
                    style = {{
                      color: '#564d6b',
                      marginTop: '25px',
                      textAlign: 'center', 
                      fontSize: '30px',
                    }}
                  >XMVB</Card.Title>
                  <Card.Img className='productsimgcompute' src={xmvb} />
                  <Card.Body>
                    <Card.Text
                      style = {{
                        color: '#564d6b',
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginBottom: '10px',
                        textAlign: 'center', 
                      }}>
                      {i18n.t('Products.xmvbintro')}
                    </Card.Text>
                  </Card.Body>
                </Card>
                </a>
              </Col>
            ))}

            {Array.from({ length: 1 }).map((_, idx) => (
              <Col key={idx}>
                <a href="https://xacs.xmu.edu.cn/software/mlatom" target="_blank" rel="noopener noreferrer">
                <Card className="hover-card"
                  style={{ 
                    marginTop: '0px',
                    marginBottom: '50px', 
                    marginLeft: '45px', 
                    marginRight: '30px', 
                    boxShadow: '0 4px 8px rgba(86, 77, 107, 0.2), 0 6px 20px rgba(86, 77, 107, 0.19)',
                    width: '350px',
                    height: '350px'
                  }}>
                <Card.Title
                  style = {{
                    color: '#564d6b',
                    marginTop: '25px',
                    textAlign: 'center', 
                    fontSize: '30px',
                  }}
                >XEDA</Card.Title>
                <Card.Img className='productsimgcompute' src={xeda} />
                <Card.Body>
                  <Card.Text
                   style = {{
                    color: '#564d6b',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '10px',
                    textAlign: 'center', 
                  }}>
                  {i18n.t('Products.xedaintro')}
                  </Card.Text>
                </Card.Body>
              </Card>
              </a>
            </Col>
          ))}

            {Array.from({ length: 1 }).map((_, idx) => (
              <Col key={idx}>
                <a href="https://xacs.xmu.edu.cn/software/mlatom" target="_blank" rel="noopener noreferrer">
                <Card className="hover-card"
                  style={{ 
                    marginTop: '0px', 
                    marginLeft: '45px', 
                    marginRight: '30px', 
                    boxShadow: '0 4px 8px rgba(86, 77, 107, 0.2), 0 6px 20px rgba(86, 77, 107, 0.19)',
                    width: '350px',
                    height: '350px'
                  }}>
                  <Card.Title
                  style = {{
                    color: '#564d6b',
                    marginTop: '25px',
                    textAlign: 'center', 
                    fontSize: '30px',
                  }}
                  >MLatom</Card.Title>
                  <Card.Img className='productsimgcompute' src={mlatom} />
                  <Card.Body>
                  <Card.Text
                   style = {{
                    color: '#564d6b',
                    marginLeft: '20px',
                    marginRight: '20px',
                    marginBottom: '10px',
                    textAlign: 'center', 
                  }}>
                  {i18n.t('Products.mlatomintro')}
                  </Card.Text>
                  </Card.Body>
                </Card>
                </a>
              </Col>
            ))}
    </Row>

    </div>
  );
}


export default Products;