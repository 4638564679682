import React from 'react';
import NavBar from '../components/NavBar';
import { Footer } from '../components/Footer';
import "../App.css";
import Products from '../components/Products';

const ProductsPage = () => {
  return (
    <div>
      <NavBar />
      <Products />
      <Footer />
    </div>
  );
};

export default ProductsPage;
