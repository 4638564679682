import "../App.css";
// import mlatom from "../assets/img/mlatom.png";
import { Footer } from "../components/Footer";
import Navbar from "../components/NavBar";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import parser from 'html-react-parser'

export default function SingleNewsPage() {
  const { id } = useParams();
  const { loading, error, data } = useFetch(`http://47.115.164.156:1337/api/posts/${id}`);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  const post = data.data;
  const htmlContent = post.attributes.ckeditor1;

  return (
    <div className="newsPost">
      <Navbar />
      <div className="newsPostWrapper">
        <h1 className="newsPostTitle">
          {post.attributes.Title}
        </h1>
        <div className="newsPostInfo">
          {post.attributes.Date}
        </div>
        <div className="newsPostDesc">
          <div className="ck-content">
            {parser(htmlContent)}
          </div>
          {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          {post.attributes.ckeditor1} */}
        </div>
      </div>
      <Footer />
    </div>
  );
};