import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import '../App.css';
import { NavBar } from "../components/NavBar";
import { Banner } from "../components/Banner";
import { Projects } from "../components/Projects";
import { ProjectCard } from "../components/ProjectCard";
import { Footer } from "../components/Footer";
import { Contact } from "../components/Contact";
import React, { useState, useEffect } from "react";
import SingleNews from "../components/SingleNews";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Home() {
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/news");
  };

  return (
    <div className="Home">
      <NavBar />
      <Banner />
      <Projects />
      {/* <Contact /> */}

      <div className='posts'>
        <h2>
          {i18n.t('News.news')}
        </h2>
        <SingleNews/>
        <button onClick={handleNavigate} className="all-news-button">
          {i18n.t('News.allnews')}
        </button>
      </div>
      
      <Footer /> 
    </div>
  );
}

export default Home;
